import React, { useState, useRef } from "react";

let UserContext = React.createContext();
let { Provider, Consumer } = UserContext;

function UserProvider({ children }) {
  const [hide, setHide] = useState(true);
  const handleOcultar = () => {
    setHide(!hide);
  };
  const [ocultarGuia, setOcultarGuia] = useState("visible");
  const [cambiarName, setCambiarName] = useState(false);
  const [tamañoLetra, setTamañoLetra] = useState(16);
  const [colorT, setColor] = useState("white");
  const [nombreTamaño, setNombreTamaño] = useState("16px");
  const [nombreColor, setNombreColor] = useState("Blanco");

  const [valorInicialAncho, setValorinicialAncho] = useState("1160");
  const [valorInicialAlto, setValorinicialAlto] = useState("600");

  const handleInput = () => {
    setValorinicialAncho(parseInt(anchoInput.current.value));
    setValorinicialAlto(parseInt(altoInput.current.value));
  };

  const anchoInput = useRef(null);
  const altoInput = useRef(null);

  // const ancho = parseInt(anchoInput.current.value);

  const handleOcultarGuias = () => {
    if (ocultarGuia === "hidden") {
      setOcultarGuia("visible");
      setCambiarName(!cambiarName);
    } else {
      setOcultarGuia("hidden");
      setCambiarName(!cambiarName);
    }
  };

  const handleColor = () => {
    if (colorT === "white") {
      setColor("red");
      setNombreColor("Rojo");
    } else if (colorT === "red") {
      setColor("#1a82ce");
      setNombreColor("Celeste");
    } else if (colorT === "#1a82ce") {
      setColor("#e1af0d");
      setNombreColor("Amarillo");
    } else if (colorT === "#e1af0d") {
      setColor("green");
      setNombreColor("Verde");
    } else if (colorT === "green") {
      setColor("white");
      setNombreColor("Blanco");
    }
  };

  const handleTamañoFuente = () => {
    if (tamañoLetra === 16) {
      setTamañoLetra(tamañoLetra - 2);
      setNombreTamaño("14px");
    } else if (tamañoLetra === 14) {
      setTamañoLetra(tamañoLetra - 2);
      setNombreTamaño("12px");
    } else if (tamañoLetra === 12) {
      setTamañoLetra(tamañoLetra - 2);
      setNombreTamaño("10px");
    } else if (tamañoLetra === 10) {
      setTamañoLetra(tamañoLetra + 10);
      setNombreTamaño("20px");
    } else if (tamañoLetra === 20) {
      setTamañoLetra(tamañoLetra - 2);
      setNombreTamaño("18px");
    } else if (tamañoLetra === 18) {
      setTamañoLetra(tamañoLetra - 2);
      setNombreTamaño("16px");
    }
  };

  return (
    <Provider
      value={{
        handleOcultarGuias,
        ocultarGuia,
        setOcultarGuia,
        cambiarName,
        handleTamañoFuente,
        tamañoLetra,
        handleColor,
        colorT,
        valorInicialAncho,
        handleInput,
        anchoInput,
        valorInicialAlto,
        setValorinicialAlto,
        altoInput,
        nombreTamaño,
        nombreColor,
        hide,
        handleOcultar,
      }}>
      {children}
    </Provider>
  );
}

export { UserProvider, Consumer as UserConsumer, UserContext };
