import React, { Suspense } from "react";
import { Router } from "@reach/router";

import { UserProvider } from "./components/Hooks/AppContext";

import Footer from "./components/Footer";
import { Spin } from "antd";

const Home = React.lazy(() => import("./pages/Home"));
const InputList = React.lazy(() => import("./pages/InputList"));
// const OutputList = React.lazy(() => import("./pages/OutputList"));
const StagePlot = React.lazy(() => import("./pages/StagePlot"));
const Imprimir = React.lazy(() => import("./pages/Imprimir"));
const NotFound = React.lazy(() => import("./components/NotFound"));
const Radiofrecuencia = React.lazy(() => import("./pages/Radiofrecuencia"));
const Juego = React.lazy(() => import("./pages/Juego"));
const Escaneos = React.lazy(() => import("./components/Escaneos"));
const Nivel1 = React.lazy(() => import("./Niveles/Nivel1.js"));
const Nivel2 = React.lazy(() => import("./Niveles/Nivel2.js"));
const Nivel3 = React.lazy(() => import("./Niveles/Nivel3.js"));
const Nivel4 = React.lazy(() => import("./Niveles/Nivel4.js"));
const Nivel5 = React.lazy(() => import("./Niveles/Nivel5.js"));
const Nivel6 = React.lazy(() => import("./Niveles/Nivel6.js"));
const Nivel7 = React.lazy(() => import("./Niveles/Nivel7.js"));
const Nivel8 = React.lazy(() => import("./Niveles/Nivel8.js"));
const Nivel9 = React.lazy(() => import("./Niveles/Nivel9.js"));
const Nivel10 = React.lazy(() => import("./Niveles/Nivel10.js"));
const Nivel11 = React.lazy(() => import("./Niveles/Nivel11.js"));
const Nivel12 = React.lazy(() => import("./Niveles/Nivel12.js"));
const Nivel13 = React.lazy(() => import("./Niveles/Nivel13.js"));
const Nivel14 = React.lazy(() => import("./Niveles/Nivel14.js"));
const Nivel15 = React.lazy(() => import("./Niveles/Nivel15.js"));
const Nivel16 = React.lazy(() => import("./Niveles/Nivel16.js"));

function App() {
  return (
    <>
      <Suspense
        fallback={
          <>
            <div
              style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Spin size="large" />
            </div>
          </>
        }>
        <UserProvider>
          <Router>
            <NotFound default />
            <Home exact path="/" />
            <InputList exact path="/input-output-list" />
            <Radiofrecuencia exact path="/radiofrecuencia" />
            <StagePlot exact path="/stage-plot" />
            <Imprimir exact path="/imprimir" />
            <Escaneos exact path="/radiofrecuencia/escaneos" />
            <Juego exact path="/feedbacks" />
            <Nivel1 exact path="/feedbacks/nivelinicial" />
            <Nivel2 exact path="/feedbacks/nivelinicial2" />
            <Nivel3 exact path="/feedbacks/eqgrafico" />
            <Nivel4 exact path="/feedbacks/eqgrafico1" />
            <Nivel5 exact path="/feedbacks/eqgrafico2" />
            <Nivel6 exact path="/feedbacks/eqgrafico3" />
            <Nivel7 exact path="/feedbacks/eqgrafico4" />
            <Nivel8 exact path="/feedbacks/nivel8" />
            <Nivel9 exact path="/feedbacks/nivel9" />
            <Nivel10 exact path="/feedbacks/nivel10" />
            <Nivel11 exact path="/feedbacks/nivel11" />
            <Nivel12 exact path="/feedbacks/nivel12" />
            <Nivel13 exact path="/feedbacks/nivel13" />
            <Nivel14 exact path="/feedbacks/nivel14" />
            <Nivel15 exact path="/feedbacks/nivel15" />
            <Nivel16 exact path="/feedbacks/nivel16" />
          </Router>
          <Footer />
        </UserProvider>
      </Suspense>
    </>
  );
}

export default App;
