import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Provider } from "react-redux";
import { createStore } from "redux";
import reducer from "./reducers";
import "antd/dist/antd.css";
import "./index.css";
import "react-awesome-button/dist/styles.css";

const initialState = {
  user: {},
  playing: {},
  myList: [],
  trends: [
    {
      id: "5",
      name: "Guitarra Electrica",
      image:
        "https://lsv-rider-tecnico.vercel.app/static/media/gibson.dec2a75f.png",
    },
    {
      id: "6",
      name: "Guitarra Electrica",
      image:
        "https://lsv-rider-tecnico.vercel.app/static/media/guitarraelectrica.daf1c766.png",
    },
    {
      id: "7",
      name: "Guitarra Electrica",
      image:
        "https://lsv-rider-tecnico.vercel.app/static/media/guitarraelectricaprs.2abd0647.png",
    },
    {
      id: "8",
      name: "Guitarra Electrica",
      image:
        "https://lsv-rider-tecnico.vercel.app/static/media/ibanez.30e0df39.png",
    },
    {
      id: "9",
      name: "Guitarra Electrica",
      image:
        "https://lsv-rider-tecnico.vercel.app/static/media/lespaul.f545260b.png",
    },
    {
      id: "10",
      name: "Guitarra Electrica",
      image:
        "https://lsv-rider-tecnico.vercel.app/static/media/vanhalen.9c33bfcc.png",
    },
  ],
};
const store = createStore(reducer, initialState);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
serviceWorkerRegistration.register();
