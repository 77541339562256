import styled from "styled-components";

export const TextFooterr = styled.footer`
  font-size: 25px;
  text-align: center;
  margin: 20px;
  color: white;
  @media (max-width: 426px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const ImgIcon = styled.img`
  transform: scale(1);
  &:hover {
    transform: scale(1.5);
  }
  &:focus {
    transform: scale(1.5);
  }
`;
