import React from "react";
import { TextFooterr, ImgIcon } from "./styled";
import { Row, Col } from "antd";
import insta from "../../images/recursos/insta.svg";
import youtube from "../../images/recursos/youtube.svg";
import linkedin from "../../images/recursos/linkedin.svg";
import face from "../../images/recursos/face.svg";

const Footer = () => {
  return (
    <>
      <Row
        justify="center"
        align="middle"
        style={{
          margin: "0px 0",
          backgroundColor: "#8bc6ec",
          backgroundImage: "linear-gradient(270deg, #307aba 0%, #5e31d9 100%)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}>
        <Col span={24}>
          <TextFooterr>
            ¡Podes sugerirme mejoras!. ¡Seguime y Escribime!
          </TextFooterr>
        </Col>
        <Col lg={2} md={0} sm={0} xs={0}></Col>
        <Col
          lg={2}
          md={4}
          sm={12}
          xs={24}
          style={{
            textAlign: "center",
            marginBottom: "40px",
            color: "white",
            fontSize: "40px",
          }}>
          <a
            rel="noopener noreferer"
            target="__blank"
            href="https://www.instagram.com/?hl=es-la">
            <ImgIcon src={insta} alt="icono facebook" />
          </a>
        </Col>
        <Col
          lg={2}
          md={4}
          sm={12}
          xs={24}
          style={{
            textAlign: "center",
            marginBottom: "40px",
            color: "white",
            fontSize: "40px",
          }}>
          <a
            rel="noopener noreferer"
            target="__blank"
            href="https://www.facebook.com/LeonSonidoVirtual">
            <ImgIcon src={face} alt="icono facebook" />
          </a>
        </Col>
        <Col
          lg={2}
          md={4}
          sm={12}
          xs={24}
          style={{
            textAlign: "center",
            marginBottom: "40px",
            color: "white",
            fontSize: "40px",
          }}>
          <a
            rel="noopener noreferer"
            target="__blank"
            href="https://www.youtube.com/c/Le%C3%B3nSonidoVirtual/videos">
            <ImgIcon src={youtube} alt="icono facebook" />
          </a>
        </Col>

        <Col
          lg={2}
          md={4}
          sm={12}
          xs={24}
          style={{
            textAlign: "center",
            marginBottom: "40px",
            color: "white",
            fontSize: "40px",
          }}>
          <a
            rel="noopener noreferer"
            target="__blank"
            href="https://www.linkedin.com/in/ramiro-contreras-le%C3%B3n/">
            <ImgIcon src={linkedin} alt="icono facebook" />
          </a>
        </Col>

        <Col lg={2} md={0} sm={0} xs={0}></Col>
      </Row>
    </>
  );
};

export default Footer;
